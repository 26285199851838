import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {
  //data: any;

  constructor(
    public toastCtrl: ToastController
  ) { 

  }

  showToast(data: any) {

    var d = new Date();
    var n = d.toLocaleTimeString().slice(0,5);
    document.getElementById("toasttime").innerHTML = n;  
    document.getElementById("toastheading").innerHTML = data.header; 
    document.getElementById("toastmsg").innerHTML = data.message;  

    if (data.type == 'warning') {
      document.getElementById("toast-left").style["background-color"] = "orange";
      document.getElementById('exclamation').style.display = "block";
      document.getElementById('checkmark').style.display = "none";
    }
    else if (data.type == 'fail') {
      document.getElementById("toast-left").style["background-color"] = "red";
      document.getElementById('exclamation').style.display = "block";
      document.getElementById('checkmark').style.display = "none";
    }
    // success
    else {
      document.getElementById("toast-left").style["background-color"] = "green";
      document.getElementById('checkmark').style.display = "block";
      document.getElementById('exclamation').style.display = "none";
    }

    document.getElementById('toast').className = "toast-container";

    setTimeout(function(){
        document.getElementById('toast').className = "toast-hide";
    }, 8000);
    setTimeout(function(){
        document.getElementById('toast').className = "hidden";
    }, 10000);
  }

  hideToast() {
    document.getElementById('toast').className = "hidden";
  }

  /* this.toastCtrl.create({
    header: data.header,
    message: data.message,
    buttons: [
      {
        text: 'Dismiss',
        role: 'cancel',
        handler: () => {
          
        }
      }
    ],
    color: data.type ?? 'primary', // primary, success, warning, danger
    cssClass: 'bluj-toast',
    duration: 3000,
    position: 'top',
  }).then((toast) => {
    toast.present();
  }); */

}